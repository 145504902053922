































































































































import { Notify,ImagePreview,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage"
import { Vue, Component,Watch } from "vue-property-decorator";
import UpPhoto from "../../Api/UpPhoto/index"
import { AuthentStore } from "../../store/modules/Basics/Authent"
import { Authent as Authents } from "../../Api/Basics/index"
import ZoomPage from "@/impView/PageSafety";

interface AuthentType<T = string> {
  userId:T|number;
  token:T;
  title: T;
  name: T;
  idCard: T | number;
  otherId: T | number;
  message: T;
  otherTitle: T;
  otherPlaceHolder: T;
  ImgHide: T;
  leftImg: T;
  leftLoading: boolean;
  LeftUpBool: boolean;
  RightImg: T;
  RightLoading: boolean;
  RightUpBool: boolean;
  otherImg: T;
  OtherLoading: boolean;
  OtherBool: boolean;
  loadingShow:boolean;

  initData(data?: AuthentInit): void;
  handleUpImage(e?:any,index?:any):void;
  handlePreviewImg(state?:number):void;
  handleUpBtn():void;
  handleError(data:string):void;
}
interface AuthentInit<T = string> {
  authType: T | number;
  name: T;
}

@Component({ name: "Authent",components:{ HeadTop } })
export default class Authent extends ZoomPage implements AuthentType {
  userId = "";
  token = "";
  title = "学生认证";
  name = "";
  idCard = "";
  otherId = "";
  message = "";
  otherTitle = "学生认证";
  otherPlaceHolder = "请输入学生证件";
  ImgHide = "一卡通照片";
  leftImg = require("../../assets/icon/Authent/FaceImg.png");
  leftLoading = false;
  LeftUpBool = false;
  RightImg = require("../../assets/icon/Authent/BackImg.png");
  RightLoading = false;
  RightUpBool = false;
  otherImg = require("../../assets/icon/Authent/OrderImg.png");
  OtherLoading = false;
  OtherBool = false;
  loadingShow = true;

  mounted() {
    this.loadingShow = false
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
    this.token = Stroage.GetData_ && Stroage.GetData_("token")
    this.initData();
  }

  initData() {
    switch (Number(this.$route.query.authType)) {
      case 0:
        this.otherTitle = "一卡通";
        this.otherPlaceHolder = "请输入一卡通";
        this.ImgHide = "一卡通照片";
        break;
      case 1:
        this.otherTitle = "骑手编号";
        this.otherPlaceHolder = "请输入骑手编号";
        this.ImgHide = "工作照";
        break;
      case 2:
        this.otherTitle = "营业执照";
        this.otherPlaceHolder = "请输入营业执照";
        this.ImgHide = "营业执照";
        break;
      case 3:
        this.otherTitle = "营业执照";
        this.otherPlaceHolder = "请输入营业执照";
        this.ImgHide = "营业执照";
        break;
    }
    this.title = (this.$route.query.name as string) || "信息认证";

    // 数据初始化
    this.name = AuthentStore.GetName || "" ;
    this.idCard = AuthentStore.GetIdCard as string || ""
    this.otherId = AuthentStore.GetOtherCard as string || ""
    this.message = AuthentStore.GetMessage || ""
    if( AuthentStore.GetLeftImg.length ){
      this.leftImg = AuthentStore.GetLeftImg
      this.LeftUpBool = true
    }else{
      this.leftImg = require("../../assets/icon/Authent/FaceImg.png");
    }
    if( AuthentStore.GetRightImg.length ){
      this.RightImg = AuthentStore.GetRightImg
      this.RightUpBool = true
    }else{
      this.RightImg = require("../../assets/icon/Authent/BackImg.png");
    }
    if( AuthentStore.GetOtherImg.length ){
      this.otherImg = AuthentStore.GetOtherImg
      this.OtherBool = true
    }else{
      this.otherImg = require("../../assets/icon/Authent/OrderImg.png");
    }
  }

  handleUpImage(e:any,state:any){
    let State:number = state.name | 0;
    let formData = new FormData()
    formData.append("functionName","authFile")
    formData.append("file",e.file)
    switch( State ){
        case 1:
            this.leftLoading = true
            break;
        case 2:
            this.RightLoading = true
            break;
        case 3:
            this.OtherLoading = true
            break;
    }
    UpPhoto({
        userId:this.userId,
        token:this.token
    },formData).then( (res:any)=>{
        console.log( res )
        if( res.message.code === "200" ){
            switch( State ){ // leftImg RightImg otherImg
                case 1:
                    this.leftLoading = false
                    this.LeftUpBool = true
                    this.leftImg = res.data
                    AuthentStore.SetLeftImg(res.data as string)
                    break;
                case 2:
                    this.RightLoading = false
                    this.RightUpBool = true
                    this.RightImg = res.data
                    AuthentStore.SetRightImg( res.data as string )
                    break;
                case 3:
                    this.OtherLoading = false
                    this.OtherBool = true
                    this.otherImg = res.data
                    AuthentStore.SetOtherImg( res.data as string )
                    break;
            }
        }else{
            this.handleError( res.message.msg )
        }
    } )
  }

  handlePreviewImg(state?:number){
    let List:Array<string> = [];
    let bool = false;
    switch( state ){ // LeftUpBool RightUpBool OtherBool
      case 0:
        if( this.LeftUpBool ){
          List = [ this.leftImg ]
          bool = true
        }
        break;
      case 1:
        if( this.RightUpBool ){
          List = [ this.RightImg ]
          bool = true
        }
        break;
      case 2:
        if( this.OtherBool ){
          List = [ this.otherImg ]
          bool = true
        }
        break;
    }
    if( bool ){
      ImagePreview({
        images:List,
        startPosition:0
      })
    }
  }

  handleUpBtn(){
    let { LeftUpBool,RightUpBool,OtherBool,name,idCard,otherId,message,leftImg,RightImg,otherImg } = this;
    let reg :any = / /g
    name = name.replace(reg,"")
    idCard = idCard.replace(reg,"")
    otherId = otherId.replace(reg,"")
    message = message.replace(reg,"")
    if( !name.length ){ this.handleError("请输入名字"); return; }
    if( !idCard.length ){ this.handleError("请输入身份证号"); return; }
    if( !otherId.length ){ this.handleError(this.otherPlaceHolder); return; }
    if( !LeftUpBool ){ this.handleError("请上传身份证面"); return; }
    if( !RightUpBool ){ this.handleError("请上传身份证反面"); return; }
    if( !OtherBool ){ this.handleError("请上传"+this.ImgHide); return; }
    this.loadingShow = true
    Authents({
      userId:this.userId,
      token:this.token
    },{
      realName:name,
      idCard:idCard,
      numberCard:otherId,
      remark:message,
      faceImg:leftImg,
      backImg:RightImg,
      picture:otherImg,
      authType:this.$route.query.authType as string
    }).then( (res:any)=>{
      console.log( res )
      this.loadingShow = false
      if( res.message.code === "200" ){
        Toast("信息已提交等待审核")
        let time = setTimeout(()=>{
          this.$router.go(-1)
          clearTimeout( time )
        },1000)
      }else{
        this.handleError( res.message.msg )
      }
    } )
  }

  handleError( data:string ){
    let str = ""
    if ( data.length ){
        str = data
    }else{
        str = "网络繁忙..."
    }
    Notify({
        message: str,
        color: 'white',
        background: "rgba(0,0,0,.6)",
    });
  }

  @Watch("name")
  handleChangeName(newName:any,oldName:any){
    AuthentStore.SetName( newName as string )
  }

  @Watch("idCard")
  handleChangenewIdCard(newIdCard:any,oldIdCard:any){
    AuthentStore.SetIdCard( newIdCard as string )
  }

  @Watch("otherId")
  handleChangeOtherId(newOtherId:any,oldOtherId:any){
    AuthentStore.SetOtherCard( newOtherId as string )
  }

  @Watch("message")
  handleChangeMessage(newMessage:any,oldMessage:any){
    AuthentStore.SetMessage( newMessage as string )
  }
}
